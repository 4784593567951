import "index.css";
import "syntax-highlighting.css";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

window.addEventListener('DOMContentLoaded', (event) => {
  const lightbox = new PhotoSwipeLightbox({
    gallerySelector: '.photo-gallery',
    children: 'a',
    pswpModule: () => import('photoswipe')
  });
  lightbox.init();
});

console.info("Bridgetown is loaded!")
